import React, { Fragment } from "react"
import Helmet from "../components/Helmet"
import Header from "../components/Header"
import One from "../accessibilityPage/One"
import Footer from "../components/Footer"

const AccessibilityPage = () => (
  <Fragment>
    <Helmet
      title="Accessibility Statement"
      descr="ETF Stock Report is committed to making our services accessible to everyone with every effort to meet evolving standards."
      slug="/accessibility"
    />
    <Header />
    <One />
    <Footer />
  </Fragment>
)

export default AccessibilityPage
